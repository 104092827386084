import type { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';

import { redirectToSubdomain } from '../logics';

export abstract class ServerSideError extends Error {
  constructor(message: string, name: string) {
    super(message);
    this.name = name;
  }

  public abstract getServerSidePropsResult(
    context: GetServerSidePropsContext
  ): GetServerSidePropsResult<any>;

  protected getRedirectServerSidePropsResult(
    ...args: Parameters<typeof redirectToSubdomain>
  ): GetServerSidePropsResult<any> {
    redirectToSubdomain(...args);
    return { props: {} };
  }
}
