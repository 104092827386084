import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import axios from 'axios';
import qs from 'qs';
import { HEADER_KEY_LOCALE } from '@flexteam/i18n-base';

const defaultAxiosConfig = {
  // https://github.com/flex-team/flex/pull/5389#discussion_r525826787
  paramsSerializer: {
    serialize: (params: any) => qs.stringify(params, { arrayFormat: 'repeat' }),
  },
};

export function getAxiosInstance({
  apiEndpoint,
  locale,
  requestConfig,
}: {
  apiEndpoint: string;
  locale: string;
  requestConfig?: AxiosRequestConfig;
}): AxiosInstance {
  const instance = axios.create({
    ...defaultAxiosConfig,
    ...requestConfig,
    baseURL: apiEndpoint,
  });

  instance.interceptors.request.use(async config => {
    return {
      ...config,
      headers: config.headers.concat({ [HEADER_KEY_LOCALE]: locale }),
    };
  });

  return instance;
}
