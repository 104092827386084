import type { ReactNode } from 'react';
import { useMemo, useState } from 'react';
import type { AxiosContextProps } from '@flexteam/remote-hooks';
import {
  EnvContextProvider,
  type BuildtimeEnv,
  type RuntimeEnv,
} from '@flexteam/env';

import { getAxiosInstance } from './logics';
import { AxiosContextProvider } from './AxiosProvider';

export function NextEnvContextProvider({
  env,
  locale,
  children,
}: {
  env: RuntimeEnv;
  locale: string;
  children: ReactNode;
}) {
  const [instances] = useState<AxiosContextProps['instances']>(() => {
    const instancesWithEnv = {
      json: getAxiosInstance({ apiEndpoint: env.API_ENDPOINT, locale }),
      blob: getAxiosInstance({
        apiEndpoint: env.API_ENDPOINT,
        locale,
        requestConfig: { responseType: 'blob' },
      }),
    };

    return instancesWithEnv;
  });

  const contextValue = useMemo(() => {
    return { env };
  }, [env]);

  return (
    <EnvContextProvider
      env={{
        runtime: contextValue.env as RuntimeEnv,
        buildtime: process.env as unknown as BuildtimeEnv,
      }}
    >
      <AxiosContextProvider instances={instances}>
        {children}
      </AxiosContextProvider>
    </EnvContextProvider>
  );
}
