import type { PropsWithChildren } from 'react';
import type { AxiosContextProps } from '@flexteam/remote-hooks';
import { AxiosContext } from '@flexteam/remote-hooks';

export function AxiosContextProvider({
  instances,
  children,
}: PropsWithChildren<AxiosContextProps>) {
  return (
    <AxiosContext.Provider value={{ instances }}>
      {children}
    </AxiosContext.Provider>
  );
}
