import { useState } from 'react';
import type {
  SiteDesign,
  PublicJobDescriptionDetail,
} from '@flex-domains/recruiting-site-builder/base';
import { StaticUrlPagePath } from '@flex-domains/recruiting-site-builder/base';
import { SiteDesignRenderer } from '@flex-domains/recruiting-site-builder/components';
import { QueryAsyncBoundary } from '@flex-services/async-boundary';

import { getSEODescription, getSEOTitleWithJobDescription } from '../../logics';
import { NextHead } from '../../head';

interface Props {
  siteDesign: SiteDesign;
  jobDescription: PublicJobDescriptionDetail;
}

export function JobDescriptionDetailPage({
  siteDesign,
  jobDescription,
}: Props) {
  const [isHeaderExpended, setHeaderExpended] = useState(false);

  const title = getSEOTitleWithJobDescription(siteDesign, jobDescription);
  const description = getSEODescription(siteDesign);
  const image = siteDesign.logoImgUrl;

  return (
    <>
      <NextHead
        title={title}
        description={description}
        faviconUrl={siteDesign.faviconImgUrl}
        og={{ title, description, image, twitterCardType: 'summary' }}
        extra={
          <style
            dangerouslySetInnerHTML={{
              __html: `body { overflow: ${
                isHeaderExpended ? 'hidden' : 'auto'
              }; }`,
            }}
          />
        }
        notAllowedRobots
      />
      <QueryAsyncBoundary pendingFallback={null}>
        <SiteDesignRenderer
          css={{ minHeight: '100vh' }}
          siteDesign={siteDesign}
          urlPagePath={StaticUrlPagePath.JobDescriptionList}
          isHeaderExpended={isHeaderExpended}
          setHeaderExpended={setHeaderExpended}
          jobDescription={jobDescription}
        />
      </QueryAsyncBoundary>
    </>
  );
}
