import '../styles/globals.css';
import '@flexteam/ve-presets/bundle.css';

import { ReactQueryProvider } from '@flex-services/react-query-provider';
import { baseCSS } from '@flexteam/fx';
import { appWithTranslation, getNextI18NextConfig } from '@flexteam/i18n';
import { LoggerContextProvider } from '@flexteam/logger';
import { captureException, init } from '@flexteam/sentry';
import { globalCss } from '@flexteam/stitches-react';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import type { AppProps as NextAppProps } from 'next/app';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import en from '../locales/en.json';
import ko from '../locales/ko.json';
import type { InitialProps } from '../src';
import {
  CommonsProvider,
  getInitialProps,
  NextEnvContextProvider,
} from '../src';

const isServer = process.env.NEXT_IS_SERVER === 'true';
const isProductionBuild = process.env.IS_PRODUCTION_BUILD === 'true';
const appVersion = process.env.APP_VERSION!;

interface Props extends NextAppProps, InitialProps {}

function App({ Component, env, serverSideProps, pageProps }: Props) {
  globalCss(baseCSS)();

  const {
    SENTRY_DSN,
    NEXT_PUBLIC_SENTRY_RELEASE,
    STAGE,
    AMPLITUDE_API_KEY,
    AMPLITUDE_SERVER_URL,
  } = env!;
  const { locale, now, userAgent, amplitudeUserProperties, flexUTMs } =
    serverSideProps;

  const [queryClient] = useState(() => new QueryClient());

  const router = useRouter();
  const [isRouterReady, setIdRouterReady] = useState(false);

  useEffect(() => {
    init({
      isProductionBuild,
      isServer,
      dsn: SENTRY_DSN,
      release: NEXT_PUBLIC_SENTRY_RELEASE,
      environment: STAGE,
    });
  }, [NEXT_PUBLIC_SENTRY_RELEASE, SENTRY_DSN, STAGE]);

  useEffect(() => {
    setIdRouterReady(router.isReady);
  }, [router.isReady]);

  return (
    <NextEnvContextProvider env={env} locale={locale}>
      <CommonsProvider locale={locale} now={now} userAgent={userAgent}>
        <ReactQueryProvider dehydratedState={dehydrate(queryClient)}>
          <LoggerContextProvider
            enabled={isRouterReady}
            serverUrl={AMPLITUDE_SERVER_URL}
            apiKey={AMPLITUDE_API_KEY}
            userProperties={amplitudeUserProperties}
            // 모든 Logger 이벤트에 flexUTMs를 view,click,impression eventProperties 에 첨부
            flexUTMs={flexUTMs}
            defaultEventProperties={{
              appVersion,
            }}
            onInitializeError={error => {
              captureException('Amplitude 초기화에 실패했습니다.', {
                level: 'error',
                extra: { error },
              });
            }}
            options={{
              dryRun: !isProductionBuild,
            }}
          >
            <Component key={locale} {...pageProps} />
          </LoggerContextProvider>
        </ReactQueryProvider>
      </CommonsProvider>
    </NextEnvContextProvider>
  );
}

App.getInitialProps = getInitialProps;

export default appWithTranslation(
  App,
  getNextI18NextConfig({
    ko,
    en,
  })
);
