import type { GetServerSidePropsContext } from 'next';

import { VIEWER_HOST_HEADER_KEY } from '../../../constants';

export function getHost(
  context: GetServerSidePropsContext
): string | undefined {
  const hostPattern = new RegExp(
    `^(.+\\.)?${process.env.RECRUITING_SITE_ROOT_DOMAIN}$`
  );

  // Next의 host가 올바르면 그대로 사용합니다. Local 환경을 위함이기도 합니다.
  const nextHost = context.req.headers.host;
  if (nextHost && hostPattern.test(nextHost)) {
    return nextHost;
  }

  // Next Host가 올바르지 않으면, Cloud Function에서 넣어준 viewer의 host를 사용합니다.
  const viewerHost = getHeaderValue(context, VIEWER_HOST_HEADER_KEY);
  if (viewerHost && hostPattern.test(viewerHost)) {
    return viewerHost;
  }

  return undefined;
}

export function redirectToSubdomain(
  context: GetServerSidePropsContext,
  subdomain: string,
  path?: string
): void {
  const { res } = context;

  const protocol = getHeaderValue(context, 'x-forwarded-proto') ?? 'https';
  const rootDomain = process.env.RECRUITING_SITE_ROOT_DOMAIN;

  const host = `${subdomain.toLowerCase()}.${rootDomain}`;
  const pathWithStartingSlash = path ? `/${withoutStartingSlash(path)}` : '';

  const href = host + pathWithStartingSlash;
  const location = `${protocol}://${href}`;

  res.setHeader('Location', location);
  res.statusCode = 302;
  res.end();
}

export function getHeaderValue(
  context: GetServerSidePropsContext,
  key: string
): string | undefined {
  const value =
    context.req.headers[key] ?? context.req.headers[key.toLowerCase()];
  return Array.isArray(value) ? value[0] : value;
}

export function withoutStartingSlash(path: string): string {
  return path.replace(/^\//, '');
}
