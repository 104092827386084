import { Button } from '@flexteam/fx';
import { styled } from '@flexteam/stitches-react';
import { useFlexRouter } from '@flexteam/next-router';

import { NextHead } from '../../head';

import { ErrorPageLayout } from './ErrorPageLayout';

export function InternalServerErrorPage() {
  const { back } = useFlexRouter();

  return (
    <>
      <NextHead />
      <ErrorPageLayout onClickBackButton={back}>
        <ErrorPageLayout.Title>
          {/* <Translation tKey="global.error.500.title" /> */}
          서버에 연결할 수 없습니다
        </ErrorPageLayout.Title>
        <ErrorPageLayout.Description>
          {/* <Translation tKey="global.error.500.description" /> */}
          서비스 이용에 불편을 드려서 죄송합니다.
          <br />
          잠시 후 다시 시도해 주세요.
        </ErrorPageLayout.Description>
        <ChildWrapper>
          <Button size="large" onClick={() => window.location.reload()}>
            {/* <Translation tKey="global.error.500.reload" /> */}
            다시 시도하기
          </Button>
        </ChildWrapper>
      </ErrorPageLayout>
    </>
  );
}

const ChildWrapper = styled('div', { marginTop: 12 });
